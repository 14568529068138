<template>
  <CIDataTable
    :vuex="vuex"
    :settings="settings"
    :route="route"
    model="competitions"
  />
</template>
<script>
  import { competitions } from '@/config/descriptions/competitions';
  import { competitionsTexts } from '@/config/pageTexts/competitions.json';

  export default {
    name: 'CompetitionsTable',
    data() {
      return {
        vuex: {
          getter: {
            theme: 'theme/theme',
            data: 'competitions/competitions',
            fields: 'competitions/fields',
            sortableFields: 'competitions/sortableFields',
            pages: 'competitions/pages',
            loading: 'competitions/loading',
            resultCount: 'competitions/resultCount',
            totalRecords: 'competitions/totalRecords',
            originalFields: 'competitions/originalFields',
            searchableAllFields: 'competitions/searchableAllFields',
            success: 'competitions/success',
            message: 'competitions/message',
          },
          actions: {
            fields: 'competitions/handleFields',
            reset: 'competitions/handleClearFilter',
            query: 'competitions/handleGetCompetitionsByQuery',
            clone: 'competitions/handleGetCompetitionsToClone',
            status: 'competitions/handleUpdateCompetitionsState'
          },
        },
        settings: {
          describe: { ...competitions },
          page: { ...competitionsTexts },
          statuses: ['All', 'Deleted', 'Ready', 'Active', 'Finished', 'Finalised', 'Cancelled', 'Paused'],
          defaultStatus: 'Active',
        },
        route: {
          create: 'CreateCompetition',
          edit: 'EditCompetition',
          preview: 'PreviewCompetition',
        },
      };
    },
  };
</script>